<template>
  <div
    class="selector-color-renderer"
    :class="{ selected: !!this.value }"
    :style="colors"
  >
    <span>
      {{ value || 'Select ' + this.template }}
    </span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      role="presentation"
      class="vs__open-indicator"
    >
      <path
        d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: null,
      value: null,
      template: null
    }
  },
  beforeMount() {
    const values = this.params.values
    this.value = this.params.value || this.params.default
    this.template = this.params.colDef.headerName.split(' ')[0]
    if (!this.value) return
    const bgColor = values[this.value.toLowerCase()]
    if (bgColor) {
      const color = this.getContrastColor(bgColor)
      this.colors = `
        --bgColor: ${bgColor};
        --color: ${color};
      `
    }
  },
  methods: {
    getContrastColor(bgColor) {
      const color = bgColor.startsWith('#') ? bgColor.slice(1) : bgColor

      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)

      const yiq = (r * 299 + g * 587 + b * 114) / 1000

      return yiq > 160 ? '#000' : '#fff'
    }
  }
}
</script>

<style lang="scss" scoped>
.selector-color-renderer {
  --defaultArrowColor: #92989d;
  width: 100%;
  height: 100%;
  padding: 0 7px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.selected {
    background-color: var(--bgColor);
    letter-spacing: 0.02em;
    color: var(--color);
  }
  svg {
    fill: var(--color, var(--defaultArrowColor));
  }
}
</style>
