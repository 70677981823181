<template>
  <div class="picker" :class="{ editing: isEditing }">
    <date-picker
      v-model="value"
      type="month"
      format="MMM YYYY"
      :placeholder="stringValue"
      @input="stopEditing"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      value: null,
      today: new Date(),
      api: null,
      isEditing: null
    }
  },
  beforeMount() {
    this.value = this.params.value ? new Date(this.params.value) : ''
    this.api = this.params.api
    this.isEditing = this.params.cellStartedEdit
  },
  mounted() {
    if (this.isEditing) {
      this.startEditing()
    }
  },
  computed: {
    stringValue() {
      if (this.value instanceof Date) {
        return this.value.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short'
        })
      }
      return this.value || 'MMM YYYY'
    }
  },
  methods: {
    getValue() {
      return this.stringValue
    },
    startEditing() {
      this.$nextTick(() => {
        this.$el.querySelector('input').focus()
      })
    },
    stopEditing(value) {
      if (!value || value === 'MMM YYYY') {
        this.value = ''
      }
      this.api.stopEditing()
    }
  }
}
</script>

<style lang="scss">
.picker {
  position: relative;
  width: 100%;
  cursor: pointer;
  pointer-events: none;
  &.editing {
    pointer-events: auto;
  }
  .mx-input {
    border: 0;
    background: transparent;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .mx-datepicker {
    width: 100%;
  }
}
</style>
