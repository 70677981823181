<template>
  <div class="link-wrapper">
    <span class="link-text">{{ value }}</span>
    <a class="link-button" :href="value" v-if="value" target="_blank">
      <img src="../../../assets/icons/link.svg" alt="" class="link-button__img">
    </a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        value: null
      }
    },
    beforeMount() {
      this.value = this.params.value;
    }
  }
</script>

<style lang="scss" scoped>
.link-wrapper {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr 20px;
  column-gap: 10px;
}
.link-text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.link-button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
  transition: background-color .25s ease;
  background-color: #f0f0f0;
  &:hover {
    background-color: #9f9f9f;
  }
  &__img {
    width: 100%;
    height: 100%;
  }
}
</style>