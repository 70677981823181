<template>
  <div @keydown="onKeyDown">
    <vue-editor v-model="content" />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      content: '',
      editor: null
    }
  },
  mounted() {
    this.content = this.params.value || ''
  },
  methods: {
    getValue() {
      return this.content === '<p><br></p>' ? '' : this.content
    },
    onKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        this.content = this.content.replace(
          /(<p><br><\/p>)(?!.*<p><br><\/p>)/,
          ''
        )
        this.params.api.stopEditing()
      } else if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vue2-editor/dist/vue2-editor.css';
.quillWrapper {
  background-color: #fff;
}
</style>
