<template>
  <div style="width: 100%">
    <div class="loader" v-if="isLoading"></div>

    <div class="words-wrap" v-else>
      <span class="words-count">
        {{ value || 0 }}
      </span>
      <div class="btns-wrap">
        <button
          @click="edit"
          class="words-btn"
          id="edit-words"
          :disabled="disabled"
        >
          <img src="../../../assets/icons/edit.svg" alt="" />
        </button>

        <button
          @click="refresh"
          class="words-btn"
          id="refresh-words"
          :disabled="disabled"
        >
          <img src="../../../assets/icons/refresh.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: null,
      isLoading: true,
      context: null,
      api: null
    }
  },
  beforeMount() {
    this.value = this.params.value
    this.context = this.params.context.componentParent
    this.api = this.params.api
  },
  mounted() {
    this.isLoading = false
  },
  computed: {
    disabled() {
      return !this.params.data.finalContent
    }
  },
  methods: {
    edit() {
      const { rowIndex } = this.params.node
      const { field: colKey } = this.params.colDef
      this.params.colDef.editable = true
      // this.context.editWordsCell(this.params.data._id);
      this.api.startEditingCell({
        rowIndex,
        colKey
      })
    },
    refresh() {
      this.context.refreshWordsCell(
        this.params.data._id,
        this.params.data.finalContent
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.words-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btns-wrap {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
}
.words-btn {
  border: 0;
  outline: 0;
  padding: 5px;
  border-radius: 5px;
  height: 25px;
  height: 25px;
  background-color: #f0f0f0;
  transition: background-color 0.25s ease;
  &[disabled] {
    opacity: 0.5;
  }
  &:not([disabled]):hover {
    background-color: #9f9f9f;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 120px;
  height: 20px;
  -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) 0/20%;
  background: linear-gradient(#000 0 0) 0/0% no-repeat #ddd;
  animation: l4 2s infinite steps(6);
}
@keyframes l4 {
  100% {
    background-size: 120%;
  }
}
</style>
