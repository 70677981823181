<template>
  <div class="custom-selector-wrap">
    <div class="custom-select-renderer">
      <span class="custom-select-renderer__value">
        {{ (outputValue || value) || 'Select ' + this.template }}
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        value: null,
        template: null,
        outputValue: null,
      }
    },
    beforeMount() {
      this.value = this.params.value;
      this.template = this.params.colDef.headerName.split(' ')[0];
      this.outputValue = this.params.valueFormatted;
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
.custom-selector-wrap {
  width: 100%;
}
.custom-select-renderer {
  display:  flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  svg {
    width: 14px;
    height: 10px;
  }
}
</style>