export const priorityColor = {
  urgent: '#ebbdc3',
  high: '#fce8b2',
  normal: '#c9daf8',
  low: '#87909e'
}

export const statusColor = {
  // hanna
  assigned: '#FFBA08',
  'in progress': '#fce8b2',
  'have questions': '#ebbdc3',
  'approval needed': '#c9daf8',
  done: '#b7e1cd',
  // pms
  'sent for approval': '#fce8b2',
  'approved & needs publishing': '#c9daf8',
  published: '#b7e1cd',
  'disapproved by client': '#87909e'
}

export const contentTypeColor = {
  new: '#e2e2e2',
  update: '#e2e2e2',
  translate: '#e2e2e2'
}
