<template>
  <div class="content-buttons">
    <div id="add-btn" class="add-button">
      <button
        class="add-button-text"
        @click="createRows"
      >Add Row(s)</button>
      <input
        type="number"
        name=""
        id="add-counter"
        class="add-row-counter"
        min="1"
        max="10"
        v-model="createRowCount"
      />
    </div>
    <button 
      id="delete-selected-btn"
      class="del-button"
      :class="{ disabled: delDisable }"
      @click="handleDelete"
      >
      Delete All Selected
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        createRowCount: 1,
        disabled: true,
      }
    },
    props: {
      gridApi: Object,
      delDisable: Boolean
    },
    computed: {
      selectedRows() {
        return this.gridApi.getSelectedRows();
      }
    },
    methods: {
      ...mapGetters([
        'getSelectedProject'
      ]),
      createRows() {
        this.$emit('add-rows', this.createRowCount)
        this.createRowCount = 1
      },
      handleDelete() {
        this.$emit('del-rows', this.gridApi.getSelectedRows());
      },
      getSelectedRows() {
        return this.gridApi.getSelectedRows();
      }
    },
  }
</script>

<style lang="scss" scoped>
.content-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.add-button {
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #7987FF;
  font-size: 16px;
  border-radius: 0;
  min-height: 44px;
}
.add-button-text {
  background-color: transparent;
  color: #7987FF;
  transition: background-color .25s ease, color .25s ease;
  border: 0;
  padding: 8px 10px;
  border-radius: 0;
  &:hover {
    background-color: #7987FF;
    color: white;
  }
}
.add-row-counter {
  border: 0;
  border-left: 1px solid #7987FF;
  color: #7987FF;
  padding: 8px 4px;
  border-radius: 0;
  width: 40px;
  text-align: center;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
     -webkit-appearance: none;
      margin: 0;
  }
  &:focus, &:focus-visible {
    border: 1px solid #7987FF;
    border-left: 2px solid #7987FF;
    outline: none;
  }
}
.del-button {
  font-size: 16px;
  padding: 8px 10px;
  color: #b13a41;
  background-color: #fff;
  border: 1px solid #b13a41;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  min-height: 44px;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #b13a41;
    color: #fff;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>