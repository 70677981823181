<template>
  <div class="add-row-container">
    <button v-on:click="createRows" class="add-button">Add</button>
    <input
      type="number"
      name=""
      id="add-counter"
      class="add-row-counter"
      min="1"
      max="10"
      v-model="createRowCount"
    />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        createRowCount: 1,
      }
    },
    
    methods: {
      createRows() {
        this.$emit('add-row-status', this.createRowCount)
        this.createRowCount = 1
      }
    },
  }
</script>

<style lang="scss" scoped>
.add-row-container {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
}
.add-button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 16px;
  transition: background-color .25s ease;
  &:hover {
    background-color: rgba(121, 135, 255, .25);
  }
}
.add-row-counter {
  border-radius: 10px;
  border: 1px solid black;
  padding: 2px 6px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
     -webkit-appearance: none;
      margin: 0;
  }
}
</style>